import React from "react"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 style={{
      textAlign: 'center',
      paddingTop: '40vh',
      paddingBottom: '40vh',
    }}>404 NOT FOUND</h1>
  </Layout>
)

export default NotFoundPage
